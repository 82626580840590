var Video =
{
    click: function(ref)
    {
        var video = $(ref).parent().find('.header-video').first();
        video.css("left","0");
        video.find('iframe').first().attr('src', 'https://player.vimeo.com/video/' + video.data('url') +  '?api=1&amp;byline=0&amp;portrait=0&amp;autoplay=1');
    }
};

var Page = 
{
    init: function() {
        $(document).ready(function() {
            $('.section_anchor').on('click', function(e) {
                var page = $(this).attr('href');
                var speed = 900;
                $('html, body').animate( { scrollTop: $(page).offset().top }, speed );
                e.preventDefault();
                return false;
            });

            $( '#navToggle' ).on( 'click', function( e ) {
                e.preventDefault();
                $( '.menu-mobile' ).toggleClass( 'is-opened' );
                $( '#navToggle' ).toggleClass( 'is-nav-opened' );
            });

            $('.select-script').change(function(event) {
                $(this).parent().find('span').first().text($(this).find('option:selected').first().text());
            });

            $('.menu-mobile .submenu').each(function(index, el) {
               $(el).parent().find('a').first().click(function(event) {
                   $(this).parent().find('.submenu').first().toggleClass('open');
               });
            });

            // Sticky Menu
            var controller = new ScrollMagic.Controller();
            var headerScene = new ScrollMagic.Scene({triggerElement: "#sticky-top", offset: "95px"})
                .setClassToggle("body", "header-fixed")
                // .addIndicators({name: "1 - header a class"})
                .addTo(controller);
            headerScene.triggerHook('onLeave');
        });
    }
};

var Slider =
{
    init: function() {
        $(document).ready(function() {
            $('.carousel').slick({
                arrows: false,
                dots: true,
                infinite: true
            });
        });
    }
};

var Grid =
{
    init: function() {
        $(document).ready(function() {
            $('.grid').masonry({
              itemSelector: '.grid-item',
              percentPosition: true
            });
        });
    }
};